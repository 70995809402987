import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <title>Loader Logo</title>
    <g>
      <g id="B" transform="translate(11.000000, 5.000000)">
        <path
          d="M41.016 50.104V44.504H59.888C59.9627 47.3787 59.5893 50.104 58.768 52.68C57.9467 55.2187 56.7147 57.4773 55.072 59.456C53.4293 61.4347 51.376 63.0027 48.912 64.16C46.4853 65.28 43.7227 65.84 40.624 65.84C37.6747 65.84 34.9493 65.3547 32.448 64.384C29.9467 63.376 27.7627 61.9573 25.896 60.128C24.0667 58.2987 22.648 56.1333 21.64 53.632C20.632 51.1307 20.128 48.3867 20.128 45.4C20.128 42.4133 20.632 39.688 21.64 37.224C22.6853 34.7227 24.1227 32.576 25.952 30.784C27.8187 28.9547 30.0027 27.5547 32.504 26.584C35.0427 25.6133 37.7867 25.128 40.736 25.128C43.312 25.128 45.6453 25.4827 47.736 26.192C49.8267 26.864 51.6933 27.8533 53.336 29.16C55.016 30.4293 56.4347 31.9787 57.592 33.808L52.216 37.392C51.1333 35.5253 49.6213 34.032 47.68 32.912C45.776 31.792 43.4613 31.232 40.736 31.232C38.1973 31.232 35.9013 31.8293 33.848 33.024C31.7947 34.1813 30.152 35.824 28.92 37.952C27.688 40.0427 27.072 42.5253 27.072 45.4C27.072 48.2373 27.6693 50.7387 28.864 52.904C30.096 55.032 31.7387 56.6933 33.792 57.888C35.8453 59.0827 38.16 59.68 40.736 59.68C42.4907 59.68 44.0587 59.4373 45.44 58.952C46.8213 58.4667 48.016 57.776 49.024 56.88C50.032 55.984 50.872 54.9573 51.544 53.8C52.216 52.6427 52.72 51.4107 53.056 50.104H41.016Z"
          fill="currentColor"
        />
      </g>
      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
                  L 11, 27
                  L 11, 72
                  L 50, 95
                  L 89, 73
                  L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
